<template lang="pug">
  .matchings(:class="{ loading }")
    BAlert(
      show
      variant="warning"
    ) {{ $t("shops_sources_car_matching.change_source_warning") }}
    BAlert(
      v-if="expiredCarsCount > 0"
      show
      variant="danger"
      :title="$t('shops_sources_car_matching.car_period_has_expired')"
    )
      | {{ $t('shops_sources_car_matching.car_period_has_expired', { count: expiredCarsCount }) }}
    BAlert(
      v-if="inactiveCarsCount > 0"
      show
      variant="danger"
      :title="$t('shops_sources_car_matching.car_with_errors')"
    )
      | {{ $t('shops_sources_car_matching.car_with_errors', { count: inactiveCarsCount }) }}
    TopBar(
      :allow-save="allowSave"
      :is-shops-with-alert-cars="isShopsWithAlertCars"
      @toggle-shops-with-alert-cars="toggleShopsWithAlertCars"
      @add-new="handleAddNew"
      @save="updateShops"
      @search="applySearch"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      ShopsSourcesCarTable(
        :selected-shops="selectedShops"
        :shops="shops"
        :shops-added="shopsAdded"
        :shops-deleted="shopsDeleted"
        :shops-updated="shopsUpdated"
        :sorting-data="sorting"
        :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
        :selected-sources-car-classes="selectedSourcesCarClassesByOtas"
        :add-selected-sources-car-class="addSelectedSourcesCarClass"
        :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
        :sources-car-classes-loading="sourcesCarClassesLoading"
        :sources-cars="sourcesCars"
        :sources-cars-loading="sourcesCarsLoading"
        :is-shops-with-alert-cars="isShopsWithAlertCars"
        :invalid-item-ids="invalidItemIds"
        @sorting="handleChangeSorting"
        @select-shop="updateShopByIndex"
        @select-car-class="updateShopByIndex"
      )
      AppPagination.pagination-padding(
        :total="shopsPagination.total_count"
        :per-page="shopsPagination.per_page"
        :current-page="shopsPagination.current_page"
        @change-pagination-data="handleChangePagination"
      )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withMatchingPageMethods from "@/mixins/matching_page/withIndexMethods"

  // store modules
  import shopSourcesCarMatchingModule from "@/config/store/matching/shops_sources_car"
  import sourcesCarClassesModule from "@/config/store/sources/car_classes"
  import sourcesCarsModule from "@/config/store/sources/cars"

  // misc
  import { every, isEmpty, reduce } from "lodash-es"

  import { mapGetters } from "vuex"

  const shopSourcesCarMatchingsMixin = withStoreModule(shopSourcesCarMatchingModule, {
    resetState: true,
    name: "shopsSourcesCarMatching",
    readers: {
      shops: "items",
      invalidItemIds: "invalidItemIds",
      selectedShops: "selectedItems",
      shopsAdded: "itemsAdded",
      shopsDeleted: "itemsDeleted",
      shopsUpdated: "itemsUpdated",
      shopsLoading: "loading",
      shopsPagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchShops: "FETCH_ITEMS",
      fetchSelectedShops: "FETCH_SELECTED_ITEMS",
      addItem: "ADD_ITEM",
      updateShops: "UPDATE_ITEMS",
      updateShopByIndex: "UPDATE_ITEM_BY_INDEX",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setInvalidItemsIds: "SET_INVALID_ITEM_IDS",
      resetAddedItems: "RESET_ITEMS_ADDED",
      resetDeletedItems: "RESET_ITEMS_DELETED",
      resetItems: "RESET_ITEMS",
      setSorting: "SET_SORTING"
    }
  })

  const sourcesCarClassesMixin = withStoreModule(sourcesCarClassesModule, {
    resetState: true,
    name: "sourcesCarClasses",
    readers: {
      sourcesCarClassesLoading: "loading",
      selectedSourcesCarClasses: "selectedItems",
      selectedSourcesCarClassesLoading: "selectedItemsLoading"
    },
    getters: { sourcesCarClassesByOtaId: "groupedByOta" },
    actions: {
      fetchSourcesCarClasses: "FETCH_ITEMS",
      fetchSelectedSourcesCarClasses: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesCarClass: "ADD_SELECTED_ITEM",
      removeSelectedSourcesCarClass: "REMOVE_SELECTED_ITEM"
    }
  })

  const sourcesCarsMixin = withStoreModule(sourcesCarsModule, {
    resetState: true,
    name: "sourcesCars",
    readers: {
      sourcesCarsLoading: "loading",
      sourcesCars: "items",
      selectedSourcesCars: "selectedItems",
      selectedSourcesCarsLoading: "selectedItemsLoading"
    },
    actions: {
      fetchSourcesCars: "FETCH_ITEMS",
      fetchSelectedSourcesCars: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesCar: "ADD_SELECTED_ITEM",
      removeSelectedSourcesCar: "REMOVE_SELECTED_ITEM"
    }
  })

  export default {
    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.isUnsavedItems })
    },

    data() {
      return {
        isShopsWithAlertCars: false
      }
    },

    computed: {
      ...mapGetters(["currentOrganization", "otasWithoutNicolas"]),

      selectedSourcesCarClassesByOtas({ selectedSourcesCarClasses, otasWithoutNicolas }) {
        return reduce(
          otasWithoutNicolas,
          (obj, ota) => {
            obj[ota.id] = selectedSourcesCarClasses[ota.id] || []

            return obj
          },
          {}
        )
      },

      loading({
        shopsLoading,
        selectedShopsLoading,
        carClassesLoading,
        selectedSourcesCarClassesLoading,
        sourcesCarClassesLoading,
        selectedSourcesCarsLoading,
        sourcesCarsLoading
      }) {
        return (
          shopsLoading ||
          selectedShopsLoading ||
          carClassesLoading ||
          selectedSourcesCarClassesLoading ||
          sourcesCarClassesLoading ||
          selectedSourcesCarsLoading ||
          sourcesCarsLoading
        )
      },

      allowSaveCarClass() {
        const sourcesCars = [...this.selectedShops, ...this.shopsAdded].map(shop => shop.sources_cars).flat()
        const carClasses = sourcesCars.map(car => car.car_class)

        return every(carClasses, ({ name }) => !isEmpty(name))
      },

      isUnsavedItems() {
        return !isEmpty(this.shopsAdded) || !isEmpty(this.shopsDeleted) || !isEmpty(this.shopsUpdated)
      },

      allowSave() {
        return this.allowSaveCarClass && this.isUnsavedItems
      },

      expiredCarsCount() {
        const cars = this.selectedShops.map(shop => shop.sources_cars).flat()

        return cars.filter(sourcesCar => sourcesCar.expired).length
      },

      inactiveCarsCount() {
        const cars = this.selectedShops.map(shop => shop.sources_cars).flat()

        return cars.filter(sourcesCar => !sourcesCar.active).length
      }
    },

    components: {
      TopBar: () => import("@/pages/ShopsSourcesCarMatching/TopBar"),
      ShopsSourcesCarTable: () => import("@/pages/ShopsSourcesCarMatching/ShopsSourcesCarTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      shopSourcesCarMatchingsMixin,
      sourcesCarClassesMixin,
      sourcesCarsMixin,
      withConfirmation,
      withMatchingPageMethods
    ],

    mounted() {
      this.fetchShops()
      this.fetchSelectedShops()
      this.fetchSelectedSourcesCarClasses()
      this.fetchSourcesCarClasses()
      this.fetchSourcesCars()
      this.fetchSelectedSourcesCars()
    },

    watch: {
      isUnsavedItems(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    methods: {
      fetchCarClasses(params = {}) {
        return this.fetchCarClassesAction({ pagination: { _disabled: true }, ...params })
      },

      handleChangePagination(paginationData) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changePagination(paginationData)
        })
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.shopsPagination, ...paginationData })
        this.fetchSelectedShops()
      },

      handleChangeSorting(newSorting) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changeSorting(newSorting)
        })
      },

      changeSorting(newSorting) {
        this.setSorting(newSorting)
        this.setPagination({ ...this.shopsPagination, current_page: 1 })
        this.fetchSelectedShops()
      },
      toggleShopsWithAlertCars() {
        this.isShopsWithAlertCars = !this.isShopsWithAlertCars
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .matchings
    .table-wrapper
      position: relative

    &.error
      border: 1px solid $default-red
      color: $default-red

  .pagination-padding
     padding-top: 25px
</style>
